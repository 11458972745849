@import 'reset.scss';

$projectborder: 5px;
$projectleftspace: 40px;
$white: #FFF;
$grey: rgb(224,224,224);
$breakpoint1: 600px;

html, body{
    font-family: 'FrutigerNext', sans-serif;
    font-weight: bold;
    font-size: 13px; // 13pt
    line-height: 1.1em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    *::-moz-selection { background-color: transparent; color: $grey}
    *::selection { background-color: transparent; color: $grey; }
    text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}
.richtext{
	font-size: 1rem;
	p{
		margin-bottom: 1em;
		text-size-adjust: 100%;
    	-webkit-text-size-adjust: 100%;
	}
	a{
		color: inherit;
		text-decoration: none;
	}
}

.view-information{
	.project--description{
		opacity: 1;
		pointer-events: auto;
    // mobile beneath each other
    @media screen and (max-width: $breakpoint1) {
      position: relative;
      left: 0;
      margin-bottom: 2.4em;
    }
	}
	.project--media-wrapper, .project--gallery{
		opacity: 0;
		pointer-events: none!important;
    // mobile beneath
    @media screen and (max-width: $breakpoint1) {
      height: 0;
    }
	}
}

.project-wrapper {
	padding-top: $projectborder;
}

.project{
	position: relative;
	padding-left: $projectborder;
	padding-right: $projectborder;
	box-sizing: border-box;
	padding-left: $projectleftspace;
	&.js-height-description{
		.project--description{
			position: relative;
			left: 0;
			right: 0;
			margin-bottom: 2.4em;
			@media screen and (min-width: $breakpoint1) {
				margin-bottom: 1.2em;
			}
		}
		.project--gallery{
			position: absolute;
			left: $projectleftspace;
			top: 0;
		}
	}
}

.project--description{
	position: absolute;
	left: $projectleftspace;
	right: $projectleftspace;
	opacity: 0;
	pointer-events: none;
	@extend .richtext;
  .project--description--text{
    max-width: calc(97rem - 40px);
  }
	@media screen and (min-width: $breakpoint1) {
		// display: flex;
		// .project--title{
		// 	width: calc(14rem - #{$projectleftspace} + #{$projectborder});
    //   width: 100%;
		// 	flex-shrink: 0;
		// 	padding-right: $projectleftspace;
		// 	box-sizing: border-box;
		// }
		// .project--description--text{
		// 	padding-right: $projectleftspace;
		// 	box-sizing: border-box;
		// }
	}
}

.project--index{
	position: absolute;
	left: $projectborder;
	&.js-sticky{
		position: fixed;
		top: $projectborder;
	}
}

.project--title{
	display: inline-block;
	// margin-bottom: 1em;
  h1{
    display: inline-block;
    &::after{
      content: ",";
    }
  }
}

.project--gallery{
	font-size: 0!important;
	&:after {
		content: "";
		clear: both;
		display: table;
	}
}

.project--media-wrapper{
	margin-bottom: 2px;
	@media screen and (min-width: $breakpoint1) {
		cursor: pointer;
		margin-right: 2px;
		float: left;
	}
}

.project--image{
	display: block;
	max-width: 100%;
	height: auto;
	background-color: rgba(255,255,255,0);
	@media screen and (min-width: $breakpoint1) {
		height: 170px;
	}
	// @media screen and (min-width: $breakpoint1) and (orientation: portrait) {
	// 	height: 140px;
	// }
}
.project--video{
	display: block;
	max-width: 100%;
	height: auto;
	background-color: rgba(255,255,255,0);
	@media screen and (min-width: $breakpoint1) {
		height: 170px;
	}
	// @media screen and (min-width: $breakpoint1) and (orientation: portrait) {
	// 	height: 140px;
	// }
}

// NAVIGATION

.mainnav{
	position: absolute;
	width: 100%;
	background-color: $white;
	z-index: 20;
	&.js-sticky{
		position: fixed;
		top: auto;
		bottom: 0;
		left: 0;
	}
}
.mainnav--list{
	font-size: 0;
	padding-left: $projectborder;
}
.mainnav--list--elem{
	font-size: 1rem;
	display: block;
	float: left;
	padding-top: $projectborder;
	padding-bottom: $projectborder + 3px;
	cursor: pointer;
	width: 50%;
	@media screen and (min-width: $breakpoint1) {
		margin-right: 0;
		width: 14rem;
	}
	&.is-hidden{
		display: none;
	}
}

// OVERLAY
.overlay{
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 80;
	display: none;
	&.js-visible{
		display: block;
	}
	&.js-visible-mobile{
		display: block;
		pointer-events: none;
		.overlay--image,.overlay--bg,.overlay--video{
			display: none;
		}
	}
}
.overlay--bg{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
  background-color: $white;
	cursor: zoom-out;
}
.overlay--image img{
  &.smaller{
    &.js-portrait{
  		height: calc(75%);
  		width: auto;
  		bottom: 12.5%;
  		transform: translateX(-50%);
  	}
  }
}
.overlay--image img{
	position: absolute;
	left: 50%;
	width: calc(100% - #{$projectleftspace});
	z-index: 10;
	cursor: e-resize;
	background-color: transparent;
	bottom: 50%;
	transform: translateY(50%) translateX(-50%);
	&.js-loading{

	}
	&.js-portrait{
		height: calc(100%);
		width: auto;
		bottom: 0;
		transform: translateX(-50%);
	}
	@media (orientation: portrait) {
		bottom: 50%;
		transform: translateY(50%) translateX(-50%);
		&.js-portrait{
			bottom: 0;
			transform: translateX(-50%);
		}
	}
}
.overlay--video video{
	position: absolute;
  top: 5px;
	left: 0;
	width: 100%;
  height: calc(100vh);
	z-index: 10;
	cursor: e-resize;
	background-color: transparent;
}
.overlay--list{
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	z-index: 10;
	font-size: 0;
	padding-left: $projectborder;
	padding-right: $projectborder;
	box-sizing: border-box;
	white-space: nowrap;
  cursor: pointer;
  background-color: #FFF;
  @media screen and (min-width: $breakpoint1) {
		background-color: transparent;
	}
  // close
  &:hover{
    color: transparent;
    &:before{
      color: black;
      font-size: 1rem;
      padding-top: 5px;
      padding-bottom: 8px;
      content: 'Index';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      background-color: transparent;
      padding-left: $projectborder;
    }
  }
}
.overlay--list-top{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	background-color: none;
	z-index: 11;
	font-size: 0;
	padding-left: $projectborder;
	padding-right: $projectborder;
	box-sizing: border-box;
	white-space: nowrap;
}
.overlay--projecttitle, .overlay--projectnumber{
	padding-right: $projectleftspace;
	box-sizing: border-box;
	@media screen and (min-width: $breakpoint1) {
		padding-right: 0;
	}
}
.overlay--projecttitle,.overlay--imagetitle, .overlay--projectnumber{
	font-size: 1rem;
	display: block;
	float: left;
	display: inline-block;
	overflow: hidden;
	padding-top: $projectborder;
	padding-bottom: $projectborder + 3px;
	width: auto;
  max-width: 100%;
	text-overflow: ellipsis;
	@media screen and (min-width: $breakpoint1) {
		width: auto;
    margin-right: 1rem;
	}
}
.overlay--imagetitle{
	@media screen and (min-width: $breakpoint1) {
		width: auto;
	}
}

// FOOTER
.footer{
	position: relative;
	padding-top: calc(1.4rem + 170px);
	padding-left: $projectborder;
  .footer-1,.footer-2{
    margin-bottom: 85px;
  }
	@media screen and (min-width: $breakpoint1) {
		.footer-upper{
			// display: flex;
		}
		.footer-1{
			// width: 14rem;
			// flex-shrink: 0;
			box-sizing: border-box;
			padding-right: $projectleftspace;
			margin-bottom: 170px;
      max-width: calc(97rem + $projectleftspace);
		}
		.footer-2{
			box-sizing: border-box;
			padding-right: $projectleftspace;
			margin-bottom: 170px;
      max-width: calc(97rem + $projectleftspace);
		}
	}
}
.footer-left{
	margin-bottom: 3.6em;
}
.footer-right{
	margin-bottom: 3.6em;
}
.footer-bottom{
	p:last-of-type{
		margin-bottom: 8px;
	}
}

// HELPER

.helper{
	position: fixed;
	display: block;
	right: $projectleftspace;
	bottom: 0;
	padding-bottom: $projectborder + 3px;
	background-color: $white;
	z-index: 100;
	pointer-events: none;
	transform: translateY(calc(100% + 100px));
	transition: transform 0s ease;
	&.js-visible{
		transform: translateY(0);
		transition: transform 0.3s ease;
	}
}
